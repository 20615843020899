
import { defineComponent, ref, reactive } from 'vue';
import { AxiosResponse } from 'axios';
import { useWalletStore } from '@/stores/wallet/WalletStore';
import { WalletData, ExpectedResponse } from '@/entities/Wallet';
import { Status } from '@/entities/Gen';
import moment from 'moment';

const columns = [
  {
    name: 'ID',
    align: 'left',
    label: 'Wallet ID',
    field: 'ID',
    sortable: true,
  },
  {
    name: 'AccountId',
    align: 'left',
    label: 'Account ID',
    field: 'AccountId',
    sortable: true,
  },
  {
    name: 'Balance',
    align: 'left',
    label: 'Balance',
    field: 'Balance',
    sortable: true,
  },
  {
    name: 'Fullname',
    align: 'left',
    label: 'Fullname',
    field: 'Fullname',
    sortable: true,
  },
  {
    name: 'CreatedAt',
    align: 'left',
    label: 'Created At',
    field: 'CreatedAt',
    sortable: true,
  },
    {
    name: 'UpdatedAt',
    align: 'left',
    label: 'Updated At',
    field: 'UpdatedAt',
    sortable: true,
  },
];
export default defineComponent({
  setup() {
    const walletStore = useWalletStore();

    const filter = '';
    const mode = 'list';
    const wallets = ref<WalletData[]>([]);
    const pagination = ref({
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 1,
    });

    const getListWallet = () => {
      const { rowsPerPage: limit, page } = pagination.value;
      walletStore.getListWallet({ page, limit }).then((res: AxiosResponse<ExpectedResponse>) => {
       if (res.data.data){
         wallets.value = res.data.data;
            for (const val of wallets.value) {
            val.CreatedAt = moment(val.CreatedAt).format('DD/MM/YYYY hh:mm')
            val.UpdatedAt = moment(val.UpdatedAt).format('DD/MM/YYYY hh:mm')
          }
        }
      });
    };

    const onPaginationUpdateNext = () => {
      if (wallets.value.length % pagination.value.rowsPerPage === 0) {
        pagination.value.page += 1;
        getListWallet()
      }
    };

      const onPaginationUpdateBefore = () => {
        if (pagination.value.page > 1) {
            pagination.value.page -= 1;
            getListWallet()
        }
    };

    getListWallet();


    return {
      onPaginationUpdateBefore,
      onPaginationUpdateNext,
      pagination,
      wallets,
      columns,
    };
  },
});
